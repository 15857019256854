import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { HomePage } from './pages/Home';
import { NotFoundPage } from './pages/NotFound';
import { PrivacyPolicyPage } from './pages/PrivacyPolicy';
import { TermsConditionsPage } from './pages/TermsConditions';
import { CaseStudiesPage } from './pages/CaseStudies';
import { CSDunderonPage } from './pages/caseStudies/Dunderon';
import { CSQuickCoachPage } from './pages/caseStudies/QuickCoach';
import { CSEatFreshPage } from './pages/caseStudies/EatFresh';
import { RoutePaths } from './RoutePaths';
import { WorkshopPage } from './pages/Workshop';
import { LoadTestingPage } from './pages/LoadTesting';
import { BookDemoPage } from './pages/BookDemo';
import { AffiliatesPage } from './pages/Affiliates';
import { CSPoliPage } from './pages/caseStudies/Poli';
import { MeteorPage } from './pages/MeteorPage';
import { RegionInBrazilPage } from './pages/RegionInBrazil';
import { CustomPage } from './pages/CustomPage';
import { CSCaughtPage } from './pages/caseStudies/Caught';
import { CSAlloHoustonPage } from './pages/caseStudies/AlloHouston';

export const AppRoutes = ({ toggleContactUs }) => {
  const location = useLocation();
  const removeHash = () => {
    const loc = window.location;
    const hist = window.history;

    // use modern browser history API
    if (hist && 'pushState' in hist) {
      hist.replaceState('', document.title, loc.pathname + loc.search);
      // fallback for older browsers
    } else {
      // prevent scrolling by storing the page's current scroll offset
      const scrollV = document.body.scrollTop;
      const scrollH = document.body.scrollLeft;

      loc.hash = '';

      // restore the scroll offset, should be flicker free
      document.body.scrollTop = scrollV;
      document.body.scrollLeft = scrollH;
    }
  };
  const scrollToAnchorOrTop = () => {
    // get URL hash (minus the hash mark)
    const hash = window.location.hash.substring(1);

    // if there's a hash, scroll to that ID
    if (hash && hash.length) {
      // setTimeout and requestAnimationFrame help ensure a true DOM repaint/reflow before we try to scroll
      // - reference: http://stackoverflow.com/a/34999925
      setTimeout(
        window.requestAnimationFrame(function () {
          const el = document.getElementById(hash);
          if (el) {
            el.scrollIntoView();
          } else if (hash === 'contact') {
            toggleContactUs();
          }
          // clean up the hash, so we don't scroll on every prop update
          removeHash();
        }),
        0
      );
      return;
    }

    window.scrollTo(0, 0);
  };
  useEffect(() => {
    scrollToAnchorOrTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  return (
    <Routes>
      <Route
        path={RoutePaths.HOME}
        element={<HomePage toggleContactUs={toggleContactUs} />}
      />
      <Route path={RoutePaths.PRIVACY_POLICY} element={<PrivacyPolicyPage />} />
      <Route
        path={RoutePaths.TERMS_AND_CONDITIONS}
        element={<TermsConditionsPage />}
      />{' '}
      <Route path={RoutePaths.CASE_STUDIES} element={<CaseStudiesPage />} />
      <Route path={RoutePaths.CS_POLI} element={<CSPoliPage />} />
      <Route path={RoutePaths.CS_CAUGHT} element={<CSCaughtPage />} />
      <Route path={RoutePaths.CS_ALLOHOUSTON} element={<CSAlloHoustonPage />} />
      <Route path={RoutePaths.CS_DUNDERON} element={<CSDunderonPage />} />
      <Route path={RoutePaths.CS_QUICKCOACH} element={<CSQuickCoachPage />} />
      <Route path={RoutePaths.CS_EATFRESH} element={<CSEatFreshPage />} />
      <Route path={RoutePaths.WORKSHOP} element={<WorkshopPage />} />
      <Route
        path={RoutePaths.REGION_IN_BRAZIL}
        element={<RegionInBrazilPage />}
      />
      <Route path={RoutePaths.ENTERPRISE} element={<CustomPage />} />
      <Route path={RoutePaths.CUSTOM} element={<CustomPage />} />
      <Route path={RoutePaths.BRING_YOUR_OWN_PROVIDER} element={<CustomPage />} />
      <Route path={RoutePaths.LOAD_TESTING} element={<LoadTestingPage />} />
      <Route path={RoutePaths.BOOK_DEMO} element={<BookDemoPage />} />
      <Route path={RoutePaths.AFFILIATES} element={<AffiliatesPage />} />
      <Route path={RoutePaths.METEOR} element={<MeteorPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
